import BGS1 from '../../../assets/images/services/service-s-1-1.png'
import { Link } from 'react-router-dom'
import React, { useState }   from 'react'


function First() {

    const [clicked, setClicked] = useState(0);
    const [lowrate, setLowrate] = useState(0);
    const [highrate, setHighrate] = useState(0);
    const [principalrate, setPrincipalrate] = useState(50000);
    const [loanyear, setLoanyear] = useState(3);


    const loancalculate = (principal_rate, interest_rates, loan_year) => {
        const loanAmount = principal_rate;
        const numberOfMonths = loan_year * 12;
        const rateOfInterest = interest_rates;
        const monthlyInterestRatio = rateOfInterest / 100 / 12;
        const top = Math.pow(1 + monthlyInterestRatio, numberOfMonths);
        const bottom = top - 1;
        const sp = top / bottom;
        const emi = loanAmount * monthlyInterestRatio * sp;
        const full = numberOfMonths * emi;
        const interest = full - loanAmount;
        const int_pge = (interest / full) * 100;
    
        const emi_str = emi
          .toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
        return emi_str;
      };


    const loancal = (lowInt, highInt) => {
        if (principalrate > 0 && loanyear > 0) {
          if (lowInt && highInt) {
            console.log(principalrate, lowInt, loanyear);
            const low_emi = loancalculate(principalrate, lowInt, loanyear);
            const high_emi = loancalculate(principalrate, highInt, loanyear);
            const pristr = `${"$"} ${low_emi} - ${"$"}${high_emi}`;
            return pristr;
          } else if (!lowInt && highInt) {
            const high_emi = loancalculate(principalrate, highInt, loanyear);
            return high_emi
          } else if (lowInt && !highInt) {
            const low_emi = loancalculate(principalrate, lowInt, loanyear);
            return low_emi
          }
        }
      };

    const faqs = [
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Quisque quis urna consequat, scelerisque",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        },
        {
            question: "Mauris a ipsum id libero sodales dapibus",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat.There are many variations of passages of available but the majority have suffered alteration that some form by injected randomised words which don’t look even as slightly believable now. Phasellus mollis ac augue sed varius. "
        },
        {
            question: "Nunc dui massa, porttitor id erat et",
            answer: "There are many variations of passages the majority have suffered alteration in some fo injected humour, or randomised words believable. Phasellus a rhoncus erat."
        }
    ]


    const compardata = [

        {
          id: 2,
          name: "xyz Bank",
          lowInt: 9.6,
          highInt: 13.85,
          fees: "**$20",
          emi: "",
        },
        {
          id: 3,
          name: "yesh Bank",
          lowInt: "Upto",
          highInt: 12.6,
          fees: "Upto 2.8%",
          emi: "",
        },
        {
            id: 1,
            name: "Axis Bank",
            lowInt: 8.4,
            highInt: 12.6,
            fees: "Upto 2.5%",
            emi: "",
          },
        {
          id: 4,
          name: "manthan Bank",
          lowInt: 5.9,
          highInt: "Onwoed",
          fees: "Upto 1.9% - 2%",
          emi: "",
        },
        {
          id: 5,
          name: "kirtan Bank",
          lowInt: 9.1,
          highInt: 13.2,
          fees: "Upto 3.2%",
          emi: "",
        },
        {
          id: 6,
          name: "hiren Bank",
          lowInt: 7.9,
          highInt: 13.2,
          fees: "**$35",
          emi: "",
        },
        {
          id: 7,
          name: "bhargav Bank",
          lowInt: 9.35,
          highInt: 13.35,
          fees: "Upto 3.5%",
          emi: "",
        },
        {
          id: 8,
          name: "siddhiesh Bank",
          lowInt: 8.5,
          highInt: 9.5,
          fees: "Upto 2.75%",
          emi: "",
        },
        {
          id: 9,
          name: "jay Bank",
          lowInt: 10.49,
          highInt: 12.99,
          fees: "	Upto 6.8%",
          emi: "",
        },
      ];


  return (
    <>
        <section className="service-details pt-120 pb-120">
            <div className="container">
                <div className="row row-gutter-y-30">
                    <div className="col-lg-4">
                        <div className="service-sidebar">
                            <div className="service-sidebar__item service-sidebar__item--menu">
                                <ul className="service-sidebar__menu">
                                    <li className="active"><Link to="#">Axis Bank</Link></li>
                                    <li><Link to="#">HDFC Bank</Link></li>
                                    <li><Link to="#">ICICI Bank</Link></li>
                                    <li><Link to="#">Kotak Mahindra Bank</Link></li>
                                    <li><Link to="#">State Bank of India</Link></li>
                                    <li><Link to="#">Tata Capital</Link></li>
                                    <li><Link to="#">Bajaj Finserv</Link></li>
                                    <li><Link to="#">Indiabulls</Link></li>
                                    <li><Link to="#">Fullerton India</Link></li>
                                    <li><Link to="#">Aditya Birla Finance</Link></li>
                                </ul>
                            </div>
                            <div className="service-sidebar__item service-sidebar__item--contact">
                                <div className="service-sidebar__bg" style={{ backgroundImage: `url(${BGS1})`}}>
                                </div>
                                <div className="service-sidebar__contact">
                                    <div className="service-sidebar__icon">
                                        <i className="icon-phone-ringing"></i>
                                    </div>
                                    <h3 className="service-sidebar__title">Quick loan
                                        proccess</h3>
                                    <hr className="service-sidebar__separator" />
                                    <p className="service-sidebar__tagline">Talk to an expert</p>
                                    <Link to="tel:+919884048348" className="service-sidebar__phone">+91 98840 48348</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="service-details__image">
                            <img src="assets/images/services/service-d-1-1.png" alt="" />
                            <div className="service-details__icon">
                                <i className="icon-diamond"></i>
                            </div>
                        </div>
                        <div className="service-details__content">
                            <h3 className="service-details__title"> <span>Axis Bank</span> Personal Loan</h3>
                            <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum
                                quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes
                                port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is
                                simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry
                                standard dummy text ever since the 1500s.</p>
                            <p>It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new design
                                printng and type setting Ipsum take a look at our round. When an unknown printer took a galley
                                of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                                but also the leap into electronic typesetting.</p>
                            <div className="row row-gutter-y-30">
                                <div className="col-md-6">
                                    <img src="assets/images/services/service-d-1-2.png" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h3 className="service-details__subtitle">Service benefits</h3>
                                    <p className="service-details__text">Duis aute irure dolor in reprehenderit in voluptate velit
                                        esse cillum.</p>
                                    <ul className="list-unstyled ml-0 service-details__list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Refresing to get such a personal touch.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Velit esse cillum eu fugiat pariatur.
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            Duis aute irure dolor in in voluptate.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>

<br/>

                <div className="compare-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Banks</th>
                        <th>Interest Rate (p.a.)</th>
                        <th>Processing Fees</th>
                        <th>EMI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {compardata.map((item) => (
                        <tr className={
                            item.name === "Axis Bank" ? "lowest_emi" : ""
                        }>
                          <td>
                            <Link to="#">{item.name}</Link>
                          </td>
                          <td className="interest-rate">
                            {item.lowInt}% - {item.highInt}%
                          </td>
                          <td>{item.fees}</td>
                          <td className="calculated-emi">
                            {loancal(item.lowInt, item.highInt)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                                <div className="col-md-12">
                                    <div className="accrodion-grp service-details__accrodion" data-grp-name="service-details__accrodion-1">
                                    {faqs.map((item, index) => (
                                        <div className={`accrodion  wow fadeInUp ${index === clicked && "active"}`} key={index} data-wow-delay="0ms">
                                            <div className="accrodion-title" onClick={() => setClicked(index)}>
                                                <h4>{item.question}</h4>
                                                <span className="accrodion-icon"></span>
                                            </div>
                                            {index === clicked && <div className="accrodion-content">
                                                <div className="inner">
                                                    <p style={{padding:"10px"}}>{item.answer}</p>
                                                </div>
                                            </div>}
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>


            </div>

         

        </section>
    </>
  )
}

export default First