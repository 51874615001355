import { Link } from "react-router-dom";
import React from "react";
import AboutImg from "../../assets/images/resources/about-4-1.png";

function Company() {
  return (
    <>
        <section className="about-four pt-120 pb-120">
            <div className="about-four__shape"></div>
            <div className="container">
            <div className="row row-gutter-y-50">
                <div className="col-lg-6">
                <div className="about-four__content">
                    <div className="block-title text-left">
                    <p className="block-title__tagline">About Company</p>
                    <h2 className="block-title__title">
                      We are here to fulfill your dreams
                    </h2>
                    </div>
                    <div className="about-four__box">
                    <div className="about-four__box__icon">
                        <i className="icon-loan"></i>
                    </div>
                    <div className="about-four__box__content">
                        <h3 className="about-four__box__title">
                        We’re in this business since 1987 and we provide the best
                        services.
                        </h3>
                    </div>
                    </div>
                    <p className="about-four__text">
                    We are a team of professional and skilled experts in all
                    domestic spheres. We offer a wide range of services and at the
                    same time we are always glad to help you with any unconventional
                    household needs.
                    </p>

                    <div className="row row-gutter-y-20">
                    <div className="col-md-6">
                        <div className="about-four__feature">
                        <div className="about-four__feature__content">
                            <div className="about-four__feature__icon">
                            <i className="icon-confirmation"></i>
                            </div>
                            <h3 className="about-four__feature__title">
                            24/7 Customer Support
                            </h3>
                        </div>
                        <div className="about-four__feature__text">
                            Our customer service is always ready to help you with any
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-four__feature">
                        <div className="about-four__feature__content">
                            <div className="about-four__feature__icon">
                            <i className="icon-confirmation"></i>
                            </div>
                            <h3 className="about-four__feature__title">
                            100% Satisfaction 
                            </h3>
                        </div>
                        <div className="about-four__feature__text">
                            We guarantee the highest quality of the work performed
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="about-four__btns">
                    <Link to="/applynow"   className="thm-btn thm-btn--dark-hover">
                       Get Free Consultation
                    </Link>
                    </div>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="about-four__image">
                    <div className="about-four__image__bg"></div>
                    <div className="about-four__image__shape"></div>
                    <img src={AboutImg} alt="" />
                    <div className="about-four__image__caption">
                    <h3 className="about-four__image__caption__year">
                        25<i>+</i>
                    </h3>
                    <p className="about-four__image__caption__text">
                        Years Experience
                    </p>
                    </div>
                    <div className="about-four__image__floated">Finance</div>
                </div>
                </div>
            </div>
            </div>
       </section>
    </>
  )
}

export default Company