import { Link } from "react-router-dom";
import React from "react";

function Safe() {
  const services = [
    {
      icon: "icon-smartphone",
      title: "Easy Application Process",
      text: "Our application process is very simple and easy to understand.",
    },
    {
      icon: "icon-operation",
      title: "Quick Loan Approval",
      text: "We provide quick loan approval within 24 hours of application.",
    },
    {
      icon: "icon-payment-gateway",
      title: "Low Interest Rate",
      text: "We provide the lowest interest rate in the market.",
    }
  ];

  return (
    <>
      <section className="service-two mb--120">
        <div className="service-two__shape"></div>
        <div className="container">
          <div className="row row-gutter-y-30">
            {services.map((service, index) => (
              <div className="col-lg-4 col-md-12" key={index}>
                <div className="service-card-two">
                  <div className="service-card-two__shape"></div>
                  <div className="service-card-two__icon">
                    <i className={service.icon}></i>
                  </div>
                  <div className="service-card-two__content">
                    <h3 className="service-card-two__title">
                      <Link to={service.link}>{service.title}</Link>
                    </h3>
                    <p className="service-card-two__text">{service.text}</p>
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Safe;
