import { Link, useLocation} from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Logo from "../../assets/images/logo-dark.png"



const Navbar = () => {
    
    const [mobile, setmobile] = useState(false)
    const [search, setsearch] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [featuresDrop, setfeaturesDrop] = useState(false)
    const [newsDrop, setnewsDrop] = useState(false)
    const [pagesDrop, setpagesDrop] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})

    const activeMenu = () => {
        if(path === "/" || path === "/home-02" || path === "/home-03" ){
            setmenu({home:true})
        }else if(path === "/about" ){
            setmenu({about:true})
        }else if(path === "/service" || path === "/servicedetails" ){
            setmenu({services:true})
        }else if(path === "/team" || path === "/teamdetails"  || path === "/creditaudit" || path === "/creditrepair" || path === "/careers" || path === "/faqs" ||  path === "/applynow"){
            setmenu({pages:true})
        }else if(path === "/personalloan" || path === "/loaneligibility" || path === "/homeloan" || path === "/homeloaneligibility" || path === "/compareEMI" || path === "/monthlyloan"){
            setmenu({features:true})
        }
        else if(path === "/newsmain" || path === "/newsdetails" ){
            setmenu({news:true})
        }
        else if(path === "/contact"){
            setmenu({contact:true})
        }else{
            setmenu({home:true})
        }
    }
    
    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

  return (
    <>
        <div className="page-wrapper">
            <div className="topbar">
                <div className="container-fluid">
                    <div className="topbar__info">
                        <Link to="#"><i className="icon-pin"></i> 2/14 kaverivi Nagar Koladi Road Thiruverkadu Chennai 600077</Link>
                        <Link to="sales@mybankshoppy.com"><i className="icon-email"></i> sales@mybankshoppy.com</Link>
                    </div>
                    <div className="topbar__links">
                        {/* <Link to="#">Login</Link>
                        <Link to="#">Company News</Link>
                        <Link to="/faqs">FAQs</Link> */}
                    </div>
                    <div className="topbar__social">
                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                        <Link to="#"><i className="fab fa-youtube"></i></Link>
                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                    </div>
                </div>
            </div>
            <nav className={`main-menu ${sticky && "stricky-header stricked-menu stricky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">

                        <svg xmlns="http://www.w3.org/2000/svg" className="main-menu__logo__shape-1" viewBox="0 0 317 120">
                            <path d="M259.856,120H0V0H274l43,37.481Z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="main-menu__logo__shape-2" viewBox="0 0 317 120">
                            <path d="M259.856,120H0V0H274l43,37.481Z" />
                        </svg>


                        <Link to="/">
                            <img src={Logo} id='logo' alt="Finlon"/>
                        </Link>
                    </div>
                    <div className="main-menu__nav">
                        <ul className="main-menu__list">
                            <li className={`dropdown ${menu.home && "current"}`}>
                                <Link to="/">Home</Link>
                             
                            </li>
                            <li className={`about ${menu.about && "current"}`}>
                                <Link to="/about">About</Link>
                            </li>

                            <li className={`dropdown ${menu.services && "current" && "hover"}`} 
                            ><Link to="/service">Services</Link>
                          
                            </li>

                            <li>
                                <Link to="/" onClick={() => setmobile(false)}>Join Us</Link>
                            </li>
                            {/* <li className={`dropdown ${menu.pages && "current"}`}><Link to="#">Pages</Link> */}
                        
                            {/* </li> */}
                            {/* <li className={`dropdown ${menu.features && "current"}`}><Link to="#">Features</Link>
                         
                            </li> */}
                            {/* <li className={`dropdown ${menu.news && "current"}`}><Link to="/newsmain">News</Link>
                           
                            </li> */}
                            <li className={`contact ${menu.contact && "current"}`}><Link to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="main-menu__right">
                        <Link to="#" onClick= {() => setmobile(true)} className="main-menu__toggler mobile-nav__toggler">
                            <i className="fa fa-bars"></i>
                        </Link>
                        <Link to="#" onClick={() => setsearch(true)} className="main-menu__search search-toggler">
                            <i className="icon-magnifying-glass"></i>
                        </Link>
                        <Link to="/applynow" className="thm-btn main-menu__btn">Request Loan</Link>
                        <Link to="tel:+919884048348" className="main-menu__contact">
                            <span className="main-menu__contact__icon">
                                <i className="icon-phone"></i>
                            </span>
                            <span className="main-menu__contact__text">
                                <strong>+91 98840 48348</strong>
                                Mon to Fri: 9 am to 6 pm
                            </span>
                        </Link>
                    </div>
                </div>
            </nav>
        </div>
        
        <div className={`search-popup ${search && "active"}`}>
            <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)}></div>
            <div className="search-popup__content">
                <form action="#">
                    <label htmlFor="search" className="sr-only">search here</label>
                    <input type="text" id="search" placeholder="Search Here..." />
                    <button type="submit" aria-label="search submit" className="thm-btn">
                        <i className="icon-magnifying-glass"></i>
                    </button>
                </form>
            </div>
        </div>

        <div className={`mobile-nav__wrapper ${mobile ? "expanded" : ""}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setmobile(false)}><i className="fa fa-times"></i></span>

                    <div className="logo-box">
                        <Link to="/" aria-label="logo image"><img src="assets/images/logo-light.png" width="155" alt="" /></Link>
                    </div>
                
                    <div className="mobile-nav__container">
                        <ul className="main-menu__list">
                            <li className="dropdown current">
                                <Link to="/" onClick={() => setmobile(false)}>Home</Link>
                       
                            </li>
                            <li>
                                <Link to="/about" onClick={() => setmobile(false)}>About</Link>
                            </li>
                            <li>
                                <Link to="/service" onClick={() => setmobile(false)}>Services</Link>
                            </li>

                            <li>
                                <Link to="/" onClick={() => setmobile(false)}>Join Us</Link>
                            </li>
                            <li>
                                <Link to="/contact" onClick={() => setmobile(false)}>Contact</Link>
                            </li>
                        </ul>

                    </div>
                    

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="icon-email"></i>
                            <Link to="mailto:sale@mybankshoppy.com">
                                sales@mybankshoppy.com
                            </Link>
                        </li>
                        <li>
                            <i className="icon-telephone"></i>
                            <Link to="tel:+919884048348"> +91 98840 48348</Link>
                        </li>
                    </ul>

                    <div className="mobile-nav__social">
                        <Link to="#"><i className="fab fa-twitter"></i></Link>
                        <Link to="#"><i className="fab fa-facebook"></i></Link>
                        <Link to="#"><i className="fab fa-youtube"></i></Link>
                        <Link to="#"><i className="fab fa-instagram"></i></Link>
                    </div>

                </div>
            
        </div>
    </>
  )
}

export default Navbar