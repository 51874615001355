import BGImg from "../../assets/images/backgrounds/slider-1-1.png";
import BGImg1 from "../../assets/images/backgrounds/slider-1-2.png";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]);

function Firstslider() {

    const sliderOptions = {
        navigation: {
            nextEl: '.slider-one__carousel__btn__left', 
            prevEl: '.slider-one__carousel__btn__right', 

    }
}

  return (
    <>
        <section className="slider-one">
            <Swiper prevE1=".slider-one__carousel__btn__right" nextE1=".slider-one__carousel__btn__left"  navigation={sliderOptions.navigation} {...sliderOptions} >
                <SwiperSlide
                    style={{cursor: "pointer"}}
                onClick={()=>{
                    window.location.href = "/about"
                }}
                className="item">
                    <div className="slider-one__item">
                    <div className="slider-one__lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="slider-one__image" 
                  
                    style={{ backgroundImage: `url(${BGImg})`}}></div>
                      
                    </div>
                </SwiperSlide>
                <SwiperSlide 
                   style={{cursor: "pointer"}}
                onClick={()=>{
                    window.location.href = "/about"
                }}
                className="item">
                    <div className="slider-one__item">
                    <div className="slider-one__lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="slider-one__image" style={{ backgroundImage: `url(${BGImg1})`}}></div>

             
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className="slider-one__carousel__btn">
            <button className="slider-one__carousel__btn__left" >
                <i className="fa fa-angle-left"></i>
            </button>
            <button className="slider-one__carousel__btn__right">
                <i className="fa fa-angle-right"></i>
            </button>
            </div>
            <div
            className="slider-one__box wow fadeInRight"
            data-wow-duration="1500ms" >
            <div className="slider-one__box__icon">
                <i className="icon-successful"></i>
            </div>
            <div className="slider-one__box__content">
                <p className="slider-one__box__tagline">Checkout our</p>
                <h3 className="slider-one__box__title">94% Success Rates</h3>
            </div>
            </div>
        </section>
    </>
  )
}

export default Firstslider